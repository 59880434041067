class Funciones {
    Moneda(value) {
        var valor = parseFloat(value).toFixed(2); 
        return new Intl.NumberFormat("de-DE").format(parseFloat(valor));
    }

    Fecha(fecha){
        return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
    }
   
}

export default Funciones;