<template>
	<div class="grid">
		<div class="col-12 lg:col-12 xl:col-12">
			<div class="card mb-0">
				<div class="grid formgrid">
					<div class="col-5 lg:col-5 xl:col-3">
						<label for="name">Desde: </label>
						<InputText id="fecha"  v-model="ini" type="date"/>
					</div>
					<div class="col-5 lg:col-5 xl:col-3">
						<label for="name">Hasta: </label>
						<InputText id="fecha"  v-model="fin" type="date"/>
					</div>
					<div class="col-2 lg:col-2 xl:col-3">
						<Button label="Mostrar" icon="pi pi-search" class="p-button-primary mr-2" @click="Buscar"/>
					</div>
				</div>
			</div>
		</div>

		<div class="col-12 lg:col-6 xl:col-3">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-800 font-medium mb-3">Citas: {{citas.total}}</span>
						<div class="text-900 font-medium text-xl">Asistencia: {{citas.asist}}</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-bell text-blue-500 text-xl"></i>
					</div>
				</div>
				<span class="text-green-500 font-medium">Pendientes por pago: </span>
				<span class="text-500">{{citas.pendpago}}</span>
			</div>
		</div>
		<div class="col-12 lg:col-6 xl:col-3">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-800 font-medium mb-3">Ingresos por Citas: ${{balance.ing}}</span>
						<div class="text-900 font-medium text-xl">Neto: ${{balance.neto}}</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-orange-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-dollar text-orange-500 text-xl"></i>
					</div>
				</div>
				<span class="text-green-500 font-medium">Honorarios Profesionales: </span>
				<span class="text-500">${{balance.hon}}</span>
			</div>
		</div>
		<div class="col-12 lg:col-6 xl:col-3">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-800 font-medium mb-3">Pacientes Atendidos</span>
						<div class="text-900 font-medium text-xl">{{pacientes.atendidos}}</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-cyan-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-user text-cyan-500 text-xl"></i>
					</div>
				</div>
				<span class="text-green-500 font-medium"> Total de Pacientes:  </span>
				<span class="text-500"> {{pacientes.total}} </span>
			</div>
		</div>
		<div class="col-12 lg:col-6 xl:col-3">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-800 font-medium mb-3">Total de Recibos</span>
						<div class="text-900 font-medium text-xl">{{pagos.recibos}}</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-purple-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-bookmark text-purple-500 text-xl"></i>
					</div>
				</div>
				<span class="text-green-500 font-medium">Total de Facturas </span>
				<span class="text-500">{{pagos.facturas}}</span>
			</div>
		</div>

	<div class="col-12 xl:col-6">
		<div class="card" style="height:400px">
			<h5>Pagos Pendientes</h5>
			<DataTable :value="citas.pendientes" :rows="5" :paginator="false" responsiveLayout="scroll"  scrollHeight="300px">
				<Column field="nombre" header="Paciente" :sortable="true" style="width:60%">
					<template #body="slotProps">
						{{slotProps.data.nombre+' '+slotProps.data.apellido}}
					</template>
				</Column>
				<Column field="deuda" header="Deuda" :sortable="true" style="width:35%">
					<template #body="slotProps">
						$ {{formatCurrency(slotProps.data.deuda)}}
					</template>
				</Column>
				<Column style="width:15%">
					<template #header>
						View
					</template>
					<template #body>
						<Button icon="pi pi-search" type="button" class="p-button-text"></Button>
					</template>
				</Column>
			</DataTable>
		</div>
	</div>
	<div class="col-12 xl:col-6">
		<div class="card" style="height:400px">
			<h5>Resumen de Ingresos</h5>
			<DataTable :value="ingresos" :rows="5" :paginator="false" responsiveLayout="scroll"  scrollHeight="300px">
				<Column field="tipopagon" header="Forma de Pago">
					<template #body="slotProps">
						{{slotProps.data.tipopagon}}
					</template>
				</Column>
				<Column field="monto" header="Monto $" style="width:35%">
					<template #body="slotProps">
						$ {{formatCurrency(slotProps.data.monto)}}
					</template>
				</Column>
				<Column field="montobs" header="Monto Bs" style="width:35%">
					<template #body="slotProps">
						Bs {{formatCurrency(slotProps.data.montobs)}}
					</template>
				</Column>
			</DataTable>
		</div>
	</div>
	<div class="col-4">
		<div class="card mb-0">
			<div class="flex justify-content-between mb-3">
				<div>
					<span class="block text-500 font-medium mb-3">Saldo de Banco</span>
					<div class="text-900 font-medium text-xl">VEF {{Moneda(saldos.montobs)}}</div>
				</div>
				<div class="flex align-items-center justify-content-center bg-orange-100 border-round" style="width:2.5rem;height:2.5rem">
					<i class="bi bi-credit-card text-orange-500 text-xl"></i>
				</div>
			</div>
		</div>
    </div>
	<div class="col-4">
		<div class="card mb-0">
			<div class="flex justify-content-between mb-3">
				<div>
					<span class="block text-500 font-medium mb-3">Saldo de Caja</span>
					<div class="text-900 font-medium text-xl">$ {{Moneda(saldos.monto)}}</div>
				</div>
				<div class="flex align-items-center justify-content-center bg-orange-100 border-round" style="width:2.5rem;height:2.5rem">
					<i class="bi bi-cash text-blue-500 text-xl"></i>
				</div>
			</div>
		</div>
    </div>
	<div class="col-4">
		<div class="card mb-0">
			<div class="flex justify-content-between mb-3">
				<div>
					<span class="block text-500 font-medium mb-3">Saldo de Zelle</span>
					<div class="text-900 font-medium text-xl">$ {{Moneda(saldos.zelle)}}</div>
				</div>
				<div class="flex align-items-center justify-content-center bg-orange-100 border-round" style="width:2.5rem;height:2.5rem">
					<i class="bi bi-cash text-blue-500 text-xl"></i>
				</div>
			</div>
		</div>
    </div>
	<div class="col-12 xl:col-6">
		<div class="card" style="height:450px">
			<h5>Top de tratamientos</h5>
			<Chart type="line" :data="lineData" :options="lineOptions" height="200"/>
		</div>
		<!--
		<div class="card">
			<div class="flex align-items-center justify-content-between mb-4">
				<h5>Notifications</h5>
				<div>
					<Button icon="pi pi-ellipsis-v" class="p-button-text p-button-plain p-button-rounded" @click="$refs.menu1.toggle($event)"></Button>
					<Menu ref="menu1" :popup="true" :model="items"></Menu>
				</div>
			</div>
			
			<span class="block text-600 font-medium mb-3">TODAY</span>
			<ul class="p-0 mx-0 mt-0 mb-4 list-none">
				<li class="flex align-items-center py-2 border-bottom-1 surface-border">
					<div class="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0">
						<i class="pi pi-dollar text-xl text-blue-500"></i>
					</div>
					<span class="text-900 line-height-3">Richard Jones
						<span class="text-700">has purchased a blue t-shirt for <span class="text-blue-500">79$</span></span>
					</span>
				</li>
				<li class="flex align-items-center py-2">
					<div class="w-3rem h-3rem flex align-items-center justify-content-center bg-orange-100 border-circle mr-3 flex-shrink-0">
						<i class="pi pi-download text-xl text-orange-500"></i>
					</div>
					<span class="text-700 line-height-3">Your request for withdrawal of <span class="text-blue-500 font-medium">2500$</span> has been initiated.</span>
				</li>
			</ul>

			<span class="block text-600 font-medium mb-3">YESTERDAY</span>
			<ul class="p-0 m-0 list-none">
				<li class="flex align-items-center py-2 border-bottom-1 surface-border">
					<div class="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0">
						<i class="pi pi-dollar text-xl text-blue-500"></i>
					</div>
					<span class="text-900 line-height-3">Keyser Wick
						<span class="text-700">has purchased a black jacket for <span class="text-blue-500">59$</span></span>
					</span>
				</li>
				<li class="flex align-items-center py-2 border-bottom-1 surface-border">
					<div class="w-3rem h-3rem flex align-items-center justify-content-center bg-pink-100 border-circle mr-3 flex-shrink-0">
						<i class="pi pi-question text-xl text-pink-500"></i>
					</div>
					<span class="text-900 line-height-3">Jane Davis
						<span class="text-700">has posted a new questions about your product.</span>
					</span>
				</li>
			</ul>
		</div>
		<div class="px-4 py-5 shadow-2 flex flex-column md:flex-row md:align-items-center justify-content-between mb-3" 
			style="border-radius: 1rem; background: linear-gradient(0deg, rgba(0, 123, 255, 0.5), rgba(0, 123, 255, 0.5)), linear-gradient(92.54deg, #1C80CF 47.88%, #FFFFFF 100.01%)">
			<div>
				<div class="text-blue-100 font-medium text-xl mt-2 mb-3">TAKE THE NEXT STEP</div>
				<div class="text-white font-medium text-5xl">Try PrimeBlocks</div>
			</div>
			<div class="mt-4 mr-auto md:mt-0 md:mr-0">
				<a href="https://www.primefaces.org/primeblocks-vue" class="p-button font-bold px-5 py-3 p-button-warning p-button-rounded p-button-raised">
					Get Started
				</a>
			</div>
		</div>
		-->
	</div>
	</div>
</template>

<script>
import API from "../service/API";
const Consulta = new API('Administracion','Dashboard');
import Funciones from "../service/Class";
const Aplic = new Funciones();

import EventBus from '@/AppEventBus';
import ProductService from '../service/ProductService';

export default {
	data() {
		return {
			ini: null,
			fin: null,
			citas: {
				total: 0,
				asist: 0,
				pendpago: 0,
				pendientes: [],
			},
			balance: {
				ing: 0,
				neto: 0,
				hon: 0,
			},
			pagos: {
				recibos: 0,
				facturas: 0,
			},
			pacientes: {
				atendidos: 0,
				total: 0,
			},
			products: null,
			lineData: {
				labels: [],
				datasets: [
					{
						label: 'Cantidad',
						data: [],
						fill: false,
						backgroundColor: '#2f4860',
						borderColor: '#2f4860',
						tension: 0.4
					},
				]
			},
			items: [
                {label: 'Add New', icon: 'pi pi-fw pi-plus'},
                {label: 'Remove', icon: 'pi pi-fw pi-minus'}
            ],
			lineOptions: null,
			ingresos: [],
			saldos: {
				monto: 0,
				montobs: 0,
				zelle: 0,
			}
		}
	},
	productService: null,
	themeChangeListener: null,
	mounted() {
		this.productService.getProductsSmall().then(data => this.products = data);

		this.themeChangeListener = (event) => {
            if (event.dark)
                this.applyDarkTheme();
            else
                this.applyLightTheme();
        };
        EventBus.on('change-theme', this.themeChangeListener);

		if (this.isDarkTheme()) {
            this.applyDarkTheme();
        }
        else {
            this.applyLightTheme();
        }
	},
	beforeUnmount() {
        EventBus.off('change-theme', this.themeChangeListener );
    },
	created() {
		this.ini = this.$store.state.fecha;
		this.fin = this.$store.state.fecha;
		this.Buscar();
		this.productService = new ProductService();
	},
	methods: {
		Buscar(){
            if(this.ini<=this.fin){
                Consulta.Procesar('Basico',{
                    ini: this.ini,
                    fin: this.fin,
                }).then(response => {
                    //this.$store.state.error = response;
					this.citas = response.result.citas;
					this.balance = response.result.balance;
					this.pagos = response.result.pagos;
					this.pacientes = response.result.pacientes;
					this.ingresos = response.result.ingresos;
					this.saldos = response.result.saldos;
					this.Ingresos();
					this.Procesar(response.result.tratamientos);
                    this.$store.commit('Loading');
                });
            } 
		},
		Procesar(data){
			this.lineData.labels = [];
			this.lineData.datasets[0].data = [];
			for (let i = 0; i < data.length; i++) {
				this.lineData.labels[i] = data[i].especialidad+' - '+data[i].producto2;
				this.lineData.datasets[0].data[i] = data[i].total;
			}

		},
		Ingresos(){
			if(this.ingresos.length>0){
				let monto=0;
				let montobs=0;
				this.ingresos.forEach(element => {
					if(element.monto>0){monto = parseFloat(monto) + parseFloat(element.monto);}
					if(element.montobs>0){montobs = parseFloat(montobs) + parseFloat(element.montobs);}
				});
				this.ingresos.push({
					tipopagon: "TOTAL",
					monedaf: 1, 
					monto: monto, 
					montobs: montobs
				});
			}
			
		},
		formatCurrency(value) {
			//return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
			return parseFloat(value).toFixed(2);
		},
		Moneda(value) {
			return Aplic.Moneda(value);
		},
		isDarkTheme() {
            return this.$appState.darkTheme === true;
        },
		applyLightTheme() {
			this.lineOptions = {
				plugins: {
					legend: {
						labels: {
							color: '#495057'
						}
					}
				},
				scales: {
					x: {
						ticks: {
							color: '#495057'
						},
						grid: {
							color:  '#ebedef',
						}
					},
					y: {
						ticks: {
							color: '#495057'
						},
						grid: {
							color:  '#ebedef',
						}
					},
				}
			};
		},
		applyDarkTheme() {
			this.lineOptions = {
				plugins: {
					legend: {
						labels: {
							color: '#ebedef'
						}
					}
				},
				scales: {
					x: {
						ticks: {
							color: '#ebedef'
						},
						grid: {
							color:  'rgba(160, 167, 181, .3)',
						}
					},
					y: {
						ticks: {
							color: '#ebedef'
						},
						grid: {
							color:  'rgba(160, 167, 181, .3)',
						}
					},
				}
			};
		}
	}
}
</script>